import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`;

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`;

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`;
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`;
const List = styled.ul`
  list-style-type: square;
  list-style-position: outside;
  list-style-image: none;
`;

const ListItem = styled.li`
  padding: 0.5rem;
`;

const PricingContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5% 10% 5% 10%;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 5% 10% 5% 10%;
    align-items: center;
    justify-content: center;
  }
`;
const PricingCard = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    border: 1px solid #ccc;
    margin: 0 1rem 0 1rem;
    background-color: white;
    color: black;
    font-family: Helvetica;
    border-radius: 0.5rem;
    flex: 1;
    margin: 1rem;
    text
  }
  @media (min-width: 768px) {
    width: 100%;
    border: 1px solid #ccc;
    margin: 0 1rem 0 1rem;
    background-color: white;
    color: black;
    font-family: Helvetica;
    border-radius: 0.5rem;
    flex: 1;
    margin: 1rem;
  }
`;

const MainPricingCard = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    border: 1px solid #ccc;
    margin: 0 1rem 0 1rem;
    background-color: blue;
    color: white;
    font-family: Helvetica;
    border-radius: 0.5rem;
    flex: 1;
  }
  @media (min-width: 768px) {
    width: 100%;
    border: 1px solid #ccc;
    margin: 0 1rem 0 1rem;
    background-color: blue;
    color: white;
    font-family: Helvetica;
    border-radius: 0.5rem;
    flex: 1;
  }
`;

const Button = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 768px) {
    width: 100%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const IndexPage = () => (
  <Layout>
    <HeaderSection>
      <HeaderText>
        <List>
          <BreadCrumb>
            <span>
              <BreadCrumbLink to="/">Home </BreadCrumbLink>
            </span>

            <span>Pricing</span>
          </BreadCrumb>
        </List>
      </HeaderText>
    </HeaderSection>
    <div
      style={{
        color: "black",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        marginTop: "0rem",
        textAlign: "center",
        fontFamily: "Helvetica",
      }}
    >
      <h1>choose your plan and start your 14-day trial</h1>
    </div>
    <div
      style={{
        color: "black",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        marginTop: "0rem",
      }}
    >
      <PricingContainer>
        <PricingCard>
          <h3 style={{ textAlign: "center" }}>Basic</h3>
          <h1 style={{ textAlign: "center" }}>$49.99/mo</h1>

          <Button>
            <a href="#" style={{ color: "black", textDecoration: "none" }}>
              Start free trial
            </a>
          </Button>
          <List>
            <ListItem>Unlimited members</ListItem>
            <ListItem>15 GB storage</ListItem>
            <ListItem>25 GB bandwidth</ListItem>
            <ListItem>500 video mins stored</ListItem>
            <ListItem>5000 video mins watched</ListItem>
          </List>
        </PricingCard>
        <MainPricingCard>
          <h3 style={{ textAlign: "center" }}>Standard</h3>
          <h1 style={{ textAlign: "center" }}>$129.99/mo</h1>
          <Button>
            <a href="#" style={{ color: "white", textDecoration: "none" }}>
              Start free trial
            </a>
          </Button>

          <List>
            <ListItem>Unlimited members</ListItem>
            <ListItem>100 GB storage</ListItem>
            <ListItem>100 GB bandwidth</ListItem>
            <ListItem>1500 video mins stored</ListItem>
            <ListItem>20000 video mins watched</ListItem>
          </List>
        </MainPricingCard>
        <PricingCard>
          <h3 style={{ textAlign: "center" }}>Professional</h3>

          <h1 style={{ textAlign: "center" }}>$249.99/mo</h1>
          <Button>
            <a href="#" style={{ color: "black", textDecoration: "none" }}>
              Start free trial
            </a>
          </Button>

          <List>
            <ListItem>Unlimited members</ListItem>
            <ListItem>300 GB storage</ListItem>
            <ListItem>250 GB bandwidth</ListItem>
            <ListItem>4000 video mins stored</ListItem>
            <ListItem>50000 video mins watched</ListItem>
          </List>
        </PricingCard>
      </PricingContainer>
    </div>
  </Layout>
);

export default IndexPage;
